import React from 'react';
import Layout from '../layout';
import terms from '../data/terms';

const TermsPage = () => (
  <Layout title="Terms" className="simple-page">
    <div className="simple-page-top-banner-container">
      <h1 className="simple-page-top-banner-title">Terms</h1>
    </div>
    <div className="simple-page-content">
      <div dangerouslySetInnerHTML={{ __html: terms }} />
    </div>
  </Layout>
);

export default TermsPage;
